import React from "react"
import Layout from "../layouts/layout/layout"
import HeaderSmall from "../components/header-small/header-small"
import HeaderImage from "../../static/media/full-check.png"
import FeatureList from "../components/feature-list/feature-list"
import DefaultLayout from "../layouts/default-layout"
import DefaultTextBlock from "../components/default-text-block/default-text-block"
import ContactButton from '../components/contact-button/contact-button'
import { Helmet } from "react-helmet"

const items = [
  { text: "Vanaf €750 + BTW", key: 1, icon: "fas fa-money-bill-wave-alt" },
  // { text: "Eigendommen tot 250m² (€1 + BTW per extra m²)", key: 2, icon: "" },
  {
    text: "Vervoer 50km inbegrepen (€0,30 + BTW per extra km)",
    key: 3,
    icon: "fas fa-car",
  },
]

export default () => (
  <Layout>
    <Helmet>
      <title>Ready-to-buy-check</title>
      <meta name="description" content="Om uw droom te verwezenlijken … daarvoor helpen we u tot het uiterste! Heeft u weinig of géén ervaring in het uitoefenen van een bod? Voelt u een bepaalde schroom of gebrek aan vertrouwen bij het onderhandelen met de eigenaar of zijn agent? We zijn gepokt en gemazeld in het onderhandelen … en doen dit graag zoals we het voor onszelf doen!"></meta>
      <meta name="keywords" context="fullcheck, full-check, vastgoed, aakoopbegeleiding, huis, pand, immo, immophone, gent, De Pinte"></meta>          
    </Helmet>
    <HeaderSmall title="Ready-to-buy-check" imageUrl={HeaderImage}></HeaderSmall>
    <DefaultLayout>
      <div className="details">
        <DefaultTextBlock
          title="Ready-to-buy-check"
          text={<div>Ready-to-buy-check = <span className="highlight">Tech-check</span> + <span className="highlight">Value-check</span> + <span className="highlight">aanbevelingen</span> (tips & tricks included) en/of ondersteuning(*) bij de onderhandelingen... tot aan de sluitende verkoopovereenkomst!<br></br><br></br>
            WE HELPEN U GRAAG TOT HET UITERSTE OM UW DROOM TE VERWEZENLIJKEN!<br></br><br></br>
            <i>Heeft u weinig of géén ervaring in het uitoefenen van een bod? Voelt u een bepaalde schroom of gebrek aan vertrouwen bij het onderhandelen met de eigenaar of zijn agent?</i><br></br><br></br>
            VRIJBLIJVENDE OPTIE: We zijn gepokt en gemazeld in het onderhandelen... en doen dit graag zoals we het voor onszelf doen!<br></br>
            Het afsluiten van een deal vergt de nodige reactiviteit en vaardigheden... zodat u met een gerust gemoed buiten stapt met een waterdichte verkoopovereenkomst en dit in uw belang. En u zorgeloos kan beginnen dromen over uw nieuwe thuis!<br></br><br></br>
            Vanaf 750€ + btw (*) op de gerealiseerde besparing wordt een bescheiden incentive gevraagd (5% op het in uw voordeel onderhandelde prijsverschil – <span className="highlight">no cure, no pay.</span> Indien géén verkoop tot stand komt - u heeft hierbij dus niks te verliezen, alleen te winnen). 
            </div>}
        ></DefaultTextBlock>
        <FeatureList items={items} title="Concreet"></FeatureList>
      </div>
      <ContactButton></ContactButton>
    </DefaultLayout>
  </Layout>
)
